import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    paddingTop: 128,
    paddingBottom: 128
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  image: {
    //perspectiveOrigin: 'left center',
    //transformStyle: 'preserve-3d',
    //perspective: 1500,
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      //transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      //boxShadow: theme.shadows[16]
    }
  },
  image2: {
    //perspectiveOrigin: 'left center',
    //transformStyle: 'preserve-3d',
    //perspective: 1500,
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      maxWidth: '80%',
      height: 'auto',
      //transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      //boxShadow: theme.shadows[16]
    }
  },
}));

const Features = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid container
        spacing={5}
        >
          <Grid
          item
          xs={12}
          md={6}
          >
            <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100%"
            >
              <Typography
              variant="h1"
              style={{
                fontSize: 35,
                color: 'black',
                fontWeight: 'bolder',
                marginBottom: 15
              }}>Why partner with TraksOS?
              </Typography>

              <Box mt={3} style={{marginBottom: 25}}>
                <Typography variant="h3" style={{marginBottom: 15}}>
                  Impossile Data Made Real
                </Typography>
                <Typography variant="body1" style={{
                  fontSize: 17,

                }}>
                  Get Real insights into how your clients are performing with your advertising.
                  No more complicated systems needed to track leads or determine volume.
                  You can proactively manage your clients and spot poor results and fix them, before losing a client.
                </Typography>
              </Box>

              <Box mt={3} style={{marginBottom: 25}}>
                <Typography variant="h3" style={{marginBottom: 15}}>
                  Powerful Tracking
                </Typography>
                <Typography variant="body1" style={{
                  fontSize: 17,

                }}>
                  Our Partner dashboard gives you in depth information on what your average closing percentage is, by 
                  business and by market. You can focus your efforts on high value regions, and save money where advertising isn't 
                  working. You get data insights where you could never get them before, giving you the tools of the big players, without
                  the big cost.
                </Typography>
              </Box>

              <Box mt={3} style={{marginBottom: 25}}>
                <Typography variant="h3" style={{marginBottom: 15}}>
                  Expand Your Product Offering
                </Typography>
                <Typography variant="body1" style={{
                  fontSize: 17,

                }}>
                  With a TraksOS Partnership, you can effectively bundle world class software services with your 
                  existing services. With TraksOS, small businesses can recognize and solve their existing 
                  problems, improve their closing percentage, and increase their revenue, leaving more money to 
                  spend on promotion.
                </Typography>
              </Box>

              <Box mt={3} style={{marginBottom: 25}}>
                <Typography variant="h3" style={{marginBottom: 15}}>
                  Pricing Freedom
                </Typography>
                <Typography variant="body1" style={{
                  fontSize: 17,

                }}>
                  As a TraksOS Partner, you get to determine the discount given to your clients. No corporate marketing departments 
                  or complex agreements. You can distribute TraksOS to your clients with the discount you think is appropriate.
                </Typography>
              </Box>

            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box display="flex" justifyContent="center" m={1} p={1}>
              <Box p={1}>
                <Box position="relative">
                  <div className={classes.image}>
                    <img
                      style={{ alignContent: 'center' }}
                      alt="Presentation"
                      src="/static/home/TraksOS_WebMain.png"
                    />
                  </div>
                </Box>
              </Box>
            </Box>

            <Box display="flex" justifyContent="center" m={1} p={1}>
              <Box p={1}>
                <Box position="relative" style={{maxWidth: '85%'}}>
                  <Typography variant="body1" style={{fontWeight: 'bold', fontSize: 20, marginBottom: 15}}>
                    "We've been using TraksOS for several months and it's been a huge help. They're 
                    obsession with providing fantastic, easy tools for small businesses for the average person has been the difference 
                    our company needed to establish an advantage."
                  </Typography>
                  <Typography variant="body1" style={{fontWeight: 'thinner', fontSize: 20}}>
                    Scott Elmore, Founder
                  </Typography>
                  <Typography variant="body1" style={{fontWeight: 'thinner', fontSize: 20, marginBottom: 5}}>
                    SEE Home Improvements
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
