import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Grid,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#121118',
    paddingTop: 110,
    paddingBottom: 110
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const Testimonials = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="md">
        <Typography
          variant="h1"
          align="left"
          style={{
            color: 'white',
            fontSize: 42,
            fontWeight: 'bold',
            marginBottom: 55
          }}
          className={classes.title}
        >
          Get started today
        </Typography>
        <Grid
          container
          spacing={5}
        >
          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Applications are fast and easy to complete"
              src="/static/images/homeImages/applyImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 24 }}>
              1: Enter Your Zipcode
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              Click the button below and give us a few details. We'll quickly get you a perfect fit.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Applications are fast and easy to complete"
              src="/static/images/homeImages/toolsImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 24 }}>
              2: Set a Budget
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              Make sure you get a professional that fits your budget
            </Typography>
          </Grid>


          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Applications are fast and easy to complete"
              src="/static/images/homeImages/generateImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 24 }}>
              3: Get the Best Options
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              Either solve your problems with a DIY solution or a professional team.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Applications are fast and easy to complete"
              src="/static/images/homeImages/growImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 24 }}>
              4: Quickly weight Options
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              Reduce the stress around your options and make a decision with confidence.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Applications are fast and easy to complete"
              src="/static/images/homeImages/upgradeImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 24 }}>
              5: Easily Chat with Pros
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              If you decide to go with a professional solution, easily get in touch wtih great options for you.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              variant="h3"
              style={{
                color: 'white',
                marginTop: 15,
                fontSize: 28
              }}>
              Go ahead and get started on finding the right home improvement professional or solution.
            </Typography>
            <Button
              size="large"
              component={RouterLink}
              to='/getSuggestion'
              style={{
                color: 'white',
                fontSize: 17,
                backgroundColor: 'black',
                marginTop: 25
              }}
              variant="contained"
              endIcon={<ArrowForward />}
            >Get Started</Button>
          </Grid>

        </Grid>
      </Container>
    </div>
  );
};

Testimonials.propTypes = {
  className: PropTypes.string
};

export default Testimonials;
