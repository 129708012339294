import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Grid,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#121118',
    paddingTop: 110,
    paddingBottom: 110
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const Testimonials = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="md">
        <Typography
          variant="h1"
          align="left"
          style={{
            color: 'white',
            fontSize: 42,
            fontWeight: 'bold',
            marginBottom: 55
          }}
          className={classes.title}
        >
          Get started today
        </Typography>
        <Grid
          container
          spacing={5}
        >
          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Applications are fast and easy to complete"
              src="/static/images/homeImages/applyImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 24 }}>
              1: Apply Online
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              Click the button below and give us a few details. We'll quickly reivew your application and respond.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Applications are fast and easy to complete"
              src="/static/images/homeImages/toolsImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 24 }}>
              2: Get tools and get trained
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              The TraksOS Partner Portal has everything you need to get trained. If you have any questions, we are just a phone call away.
            </Typography>
          </Grid>


          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Applications are fast and easy to complete"
              src="/static/images/homeImages/generateImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 24 }}>
              3: Generate Partner Links
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              Generate links to distribute to your clients and begin gathering data immediately. 
              You decide how you want to price and package the platform. Everything comes with detailed documentation.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Applications are fast and easy to complete"
              src="/static/images/homeImages/growImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 24 }}>
              4: Grow Your Data Sets
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              The more sign-ups through partner links, the more data you get. As your dataset grows, you will be able to see 
              even more detailed data.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <img
              style={{
                alignSelf: 'center',
                width: '100%'
              }}
              alt="Applications are fast and easy to complete"
              src="/static/images/homeImages/upgradeImage.png"
            />
            <Typography variant="h3" style={{ color: 'white', marginTop: 15, fontSize: 24 }}>
              5: Receive Ongoing Upgrades
            </Typography>
            <Typography variant="body1" style={{ color: 'white', marginTop: 15, fontSize: 18, fontWeight: 'thinner' }}>
              As we continue to grow, you will be given more detailed and customized data.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              justifyItems: 'center',
              justify: 'space-around',
              alignContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography
              variant="h3"
              style={{
                color: 'white',
                marginTop: 15,
                fontSize: 28
              }}>
              Join the team of partners who are build businesses and delivering next-level results to their clients.
            </Typography>
            <Button
              size="large"
              component={RouterLink}
              to='/register'
              style={{
                color: 'white',
                fontSize: 17,
                backgroundColor: 'black',
                marginTop: 25
              }}
              variant="contained"
              endIcon={<ArrowForward />}
            >Apply Now</Button>
          </Grid>

        </Grid>
      </Container>
    </div>
  );
};

Testimonials.propTypes = {
  className: PropTypes.string
};

export default Testimonials;
