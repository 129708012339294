import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import Features from './Features';
import Testimonials from './Testimonials';
import CTA from './CTA';
import FAQS from './FAQS';
import InviteConfirm from './InviteConfirm';
import ReactGA from 'react-ga';

const GA_MEASUREMENT_ID_RAW = 'G-TBFN8S38V6'

ReactGA.initialize(GA_MEASUREMENT_ID_RAW);

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomeView = () => {
  const classes = useStyles();

  const [inviteOpen, setInviteOpen] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState(false);
  const [emailRegistered, setEmailRegistered] = useState('');

  const openInvite = (email) => {
    setInviteOpen(true);
    setSubmittedEmail(true);
    setEmailRegistered(email);
    ReactGA.event({
      category: 'Email Register Invite',
      action: 'User Asked for Invite'
    })
  }

  const inviteDone = (value) => {
    console.log('invite is done');
    setInviteOpen(false);
  }

  const handleDoOver = () =>{
    setSubmittedEmail(false);
    setEmailRegistered('');
  }

  return (
    <Page
      className={classes.root}
      title="Invite"
    >

      <CTA clickInvite={openInvite} thisEmail={emailRegistered} hasSubmitted={submittedEmail} doOver={handleDoOver}/>
      <Hero clickInvite={openInvite} thisEmail={emailRegistered} hasSubmitted={submittedEmail}/>
      <Features />
      
      <FAQS />
      <InviteConfirm inviteOpen={inviteOpen} inviteDone={inviteDone} />
    </Page>
  );
};

export default HomeView;
