import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#E8A200',
    paddingTop: 50,
    paddingBottom: 50,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    },
    background: 'linear-gradient(to right bottom, #F96302, #FBC119)'
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    //perspectiveOrigin: 'left center',
    //transformStyle: 'preserve-3d',
    //perspective: 1500,
    '& > img': {
      maxWidth: '93%',
      height: 'auto',
      //transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      //boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  },
  mainBanner: {
    backgroundColor: 'orange',
  }
}));

const Hero = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
      <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="h1"
                style={{
                  fontSize: 45,
                  color: 'white',
                  fontWeight: 'bolder',
                  marginBottom: 15
                }}
              >
                Now, You "Know a Guy"
              </Typography>
              <Box mt={3}>
                <Typography
                  variant="body1"
                  style={{
                    color: 'white',
                    fontSize: 20
                  }}
                >
                  Let us help you find the best home improvement professional for your outdoor shade project. 
                  Whether it be a DIY project or you want a full-service professional from start to finish, 
                  we can help you get the best value for your home improvement project.
                </Typography>
              </Box>
              <Box mt={3}>
                
                <Box mt={3}>
                  <Grid container
                  spacing={3}
                  >
                    <Grid item style={{marginLeft: 25}}>
                      <Button
                      size="large"
                      component={RouterLink}
                      to='/getSuggestion'
                      style={{
                        color: 'white',
                        fontSize: 17,
                        backgroundColor: 'black'
                      }}
                      variant="contained"
                      endIcon={<ArrowForward />}
                      >Find a Pro</Button>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box position="relative" justifyContent="center" alignItems="center">
              <div className={classes.image}>
                <img
                  alt="Presentation"
                  src="/static/home/PartnerImage.png"
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
