import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import Features from './Features';
import Testimonials from './Testimonials';
import CTA from './CTA';
import FAQS from './FAQS';

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomeView = () => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);

  const isSubmitting = (value) => {
    setLoading(value);
  }

  return (
    <Page
      className={classes.root}
      title="Apply"
    >
      <Hero isSubmitting={isSubmitting}/>
      <Testimonials />
    </Page>
  );
};

export default HomeView;
