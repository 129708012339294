/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  CheckCircle as CheckCircle,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  Clipboard as Clipboard,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

const differentSections = [
  {
    subheader: 'Reports',
    items: [
//      {
  //      title: 'Dashboard',
    //    icon: PieChartIcon,
      //  href: '/app/reports/dashboard'
      //},
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/reports/dashboard-alternative',
        info: () => (
          <Chip
            color="secondary"
            size="small"
            label="Pro Feature"
          />
        )
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Open Leads List',
            href: '/app/management/customers'
          },
          {
            title: 'Open Lead Calendar',
            href: '/app/managment/customer_calendar'
          },
          {
            title: 'Client Archive',
            href: '/app/management/clientArchive'
          }
        ]
      },
//      {
//        title: 'Estimates/Invoices',
//        icon: ReceiptIcon,
//        href: '/app/management/invoices',
//        items: [
//          {
//            title: 'List Invoices',
//            href: '/app/management/invoices'
//          },
//          {
//            title: 'View Invoice',
//            href: '/app/management/invoices/1'
//          }
//        ]
//      },
      {
        title: 'Jobs',
        icon: BriefcaseIcon,
        href: '/app/management/jobs',
        items: [
          {
            title: 'Open Jobs List',
            href: '/app/management/jobs'
          },
          {
            title: 'Open Jobs Calendar',
            href: '/app/management/jobs_calendar'
          },
          {
            title: 'Jobs Archive',
            href: '/app/management/jobArchive'
          },
        ]
      },
      {
        title: 'Tasks',
        icon: CheckCircle,
        href: '/app/management/orders',
        items: [
          {
            title: 'My Tasks',
            href: '/app/management/myTasks'
          }
        ]
      }
    ]
  },
//  {
//    subheader: 'Applications',
//    items: [
      
//      {
//        title: 'Networking',
//        href: '/app/social',
//        icon: ShareIcon,
//        items: [
//          {
//            title: 'Profile',
//            href: '/app/social/profile'
//          },
//          {
//            title: 'Feed',
//            href: '/app/social/feed'
//          }
//        ]
//      }
//    ]
//  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Upgrade',
        href: '/pricing',
        icon: DollarSignIcon
      }
    ]
  },
  
]

const sections = [
  {
    subheader: 'Reports',
    items: [
//      {
  //      title: 'Dashboard',
    //    icon: PieChartIcon,
      //  href: '/app/reports/dashboard'
      //},
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/reports/dashboard-alternative',
        info: () => (
          <Chip
            color="secondary"
            size="small"
            label="Pro Feature"
          />
        )
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'Open Leads List',
            href: '/app/management/customers'
          },
          {
            title: 'Open Lead Calendar',
            href: '/app/managment/customer_calendar'
          },
          {
            title: 'Client Archive',
            href: '/app/management/clientArchive'
          }
        ]
      },
//      {
//        title: 'Estimates/Invoices',
//        icon: ReceiptIcon,
//        href: '/app/management/invoices',
//        items: [
//          {
//            title: 'List Invoices',
//            href: '/app/management/invoices'
//          },
//          {
//            title: 'View Invoice',
//            href: '/app/management/invoices/1'
//          }
//        ]
//      },
      {
        title: 'Jobs',
        icon: BriefcaseIcon,
        href: '/app/management/jobs',
        items: [
          {
            title: 'Open Jobs List',
            href: '/app/management/jobs'
          },
          {
            title: 'Open Jobs Calendar',
            href: '/app/management/jobs_calendar'
          },
          {
            title: 'Jobs Archive',
            href: '/app/management/jobArchive'
          },
        ]
      },
      {
        title: 'Tasks',
        icon: CheckCircle,
        href: '/app/management/orders',
        items: [
          {
            title: 'My Tasks',
            href: '/app/management/myTasks'
          },
          {
            title: 'Company Tasks',
            href: '/app/management/companyTasks'
          }
        ]
      }
    ]
  },
//  {
//    subheader: 'Applications',
//    items: [
      
//      {
//        title: 'Networking',
//        href: '/app/social',
//        icon: ShareIcon,
//        items: [
//          {
//            title: 'Profile',
//            href: '/app/social/profile'
//          },
//          {
//            title: 'Feed',
//            href: '/app/social/feed'
//          }
//        ]
//      }
//    ]
//  },
  {
    subheader: 'Settings',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Company',
        href: '/app/company',
        icon: Clipboard
      },
      {
        title: 'Upgrade',
        href: '/pricing',
        icon: DollarSignIcon
      }
    ]
  },
  
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              Your tier:
              {' '}
              <Link
                component={RouterLink}
                to="/pricing"
              >
                {user.tier}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {user.owner !== user.companyUuid ? (
            <div>
              {differentSections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
            </div>
          ) : (
            <div>
              {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname
                  })}
                </List>
              ))}
              </div>
            )}

        </Box>
        <Divider />

      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;

//        <Box p={2}>
//<Box
//p={2}
//borderRadius="borderRadius"
//bgcolor="background.dark"
//>
//<Typography
//  variant="h6"
//  color="textPrimary"
//>
//  Need Help?
//</Typography>
//<Link
//  variant="subtitle1"
//  color="secondary"
//  component={RouterLink}
//  to="/docs"
//>
//  Check our Tutorials
//</Link>
//</Box>
//</Box>