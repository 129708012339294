import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  makeStyles,
  Card,
  Checkbox,
  CardContent,
  TextField,
  FormControlLabel,
  CircularProgress
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import axios from 'axios';
import { analytics } from 'src/lib/firebase'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#E8A200',
    paddingTop: 50,
    paddingBottom: 50,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    },
    background: 'linear-gradient(to right bottom, #430089, #82ffa1)'
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    //perspectiveOrigin: 'left center',
    //transformStyle: 'preserve-3d',
    //perspective: 1500,
    '& > img': {
      maxWidth: '93%',
      height: 'auto',
      //transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      //boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  },
  mainBanner: {
    backgroundColor: 'orange',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

function validate(form) {
  let locErrors = {};

  const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  
  const isValid = expression.test(String(form.email).toLowerCase());

  if (form.email === '') {
    locErrors.email = 'Email Required'
  } else if (!isValid) {
    locErrors.email = 'Please Enter a Valid Email Address'
  }

  if (form.companyName === '') {
    locErrors.companyName = "Company Name Required"
  }

  if (form.firstName === '') {
    locErrors.firstName = 'First Name Required'
  }

  if (form.lastName === '') {
    locErrors.lastName = 'Last Name Required'
  }

  if (form.city === '') {
    locErrors.city = 'City Required'
  }

  if (form.state === '') {
    locErrors.state = 'State Required'
  }

  if (form.zipcode === '') {
    locErrors.zipcode = 'Zipcode Required'
  }

  let noErrors = true;

  if (Object.keys(locErrors).length !== 0) {
    console.log(locErrors);
    console.log('if function fired')
    noErrors = false
  };

  const isGood = locErrors === {} ? true : false;

  console.log(noErrors);

  const returnValue = {
    errors: locErrors,
    isSet: noErrors
  };

  return returnValue;
}

const Hero = ({ className, isSubmitting, ...rest }) => {
  
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [companyName, setCompany] = useState('');
  const [firstName, setFirst] = useState('');
  const [lastName, setLast] = useState('');
  const [position, setPosition] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [phone, setPhone] = useState('');
  const [contactPhone, setContactPhone] = useState(false);
  const [removedToggle, setRemovedToggle] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleCheckChange = (event, value) => {
    if (phone === '') {
      alert('A Phone Number is needed for Phone Contact');
    } else {
      if (value === false) {
        setRemovedToggle(true);
      }
      setContactPhone(value);
    }
  }

  const handlePhoneChange = (event) => {
    if (!removedToggle && event.target.value !== '') {
      setContactPhone(true);
    }
    
    console.log(event.target.value);
    const newPhone = event.target.value.replace(/ /g, '');
    if (newPhone === '') {
      setContactPhone(false);
    } else {
      console.log(event.target.value);
      const num = `${newPhone.substring(0, 3)} ${newPhone.substring(3, 6)} ${newPhone.substring(6, newPhone.length)}`;
      setPhone(num);
    }
  }

  const handleSubmit = () => {

    setLoading(true);

    let form = {
      email: email,
      companyName: companyName,
      firstName: firstName,
      lastName: lastName,
      city: city,
      state: state,
      zipcode: zipcode,
      phone: phone,
      contactPhone: contactPhone,
    }

    const validation = validate(form);

    console.log(validation);

    if (validation.isSet) {
      setErrors(null)
      const payload = {
        form: form
      };
      axios.defaults.baseURL = 'https://us-central1-see-hi.cloudfunctions.net/api';
      axios.post(`/TraksOS_Partner_SignUp`, payload).then((res) => {
        console.log('response is ', res);
        if (res.data.error) {
          setLoading(false);
          alert(res.data.error);
        } else {
          analytics.logEvent('partner_register');
          setLoading(false);
          alert(res.data.success)
        }
      })
      .catch((err) => {
        setLoading(false);
        alert(err);
      })

    } else {
      setLoading(false);
      setErrors(validation.errors);
      alert('There were Errors! Please See Highlights.')
    }
  }

  return (

    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Card style={{backgroundColor: 'white', marginBottom: 25, padding: 15}}>
          <Typography variant="h1" align="center" style={{
            fontSize: 35,
            fontWeight: 'thinner'
          }}>TraksOS Partner Application</Typography>
        </Card>
        <Card style={{ backgroundColor: 'white' }}>
          <Typography variant="body1" align="center" style={{marginTop: 15, marginBottom: 10}}>
            Please fill out all fields and then press "Submit"
          </Typography>
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid item
                xs={12}
                md={6}
              >
                <TextField
                  error={errors && errors.email !== undefined ? true : false}
                  id="email" 
                  label="Company Email"
                  name="email"
                  value={email}
                  fullWidth
                  helperText={errors && errors.email !== undefined && errors.email}
                  variant="outlined"
                  required={true}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Grid>

              <Grid item
                xs={12}
                md={6}
              >
                <TextField
                  error={errors && errors.companyName !== undefined ? true : false}
                  helperText={errors && errors.companyName !== undefined && errors.companyName}
                  id="company"
                  label="Company Name"
                  name="company"
                  value={companyName}
                  fullWidth
                  variant="outlined"
                  required={true}
                  onChange={(event) => setCompany(event.target.value)}
                />
              </Grid>

              <Grid item
                xs={12}
                md={6}
              >
                <TextField
                  error={errors && errors.firstName !== undefined ? true : false}
                  helperText={errors && errors.firstName !== undefined && errors.firstName}
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  value={firstName}
                  fullWidth
                  variant="outlined"
                  required={true}
                  onChange={(event) => setFirst(event.target.value)}
                />
              </Grid>

              <Grid item
                xs={12}
                md={6}
              >
                <TextField
                  error={errors && errors.lastName !== undefined ? true : false}
                  helperText={errors && errors.lastName !== undefined && errors.lastName} 
                  id="lastName" 
                  label="Last Name"
                  name="lastName"
                  value={lastName}
                  fullWidth
                  variant="outlined"
                  required={true}
                  onChange={(event) => setLast(event.target.value)}
                />
              </Grid>

              <Grid item
                xs={12}
                md={6}
              >
                <TextField
                  id="position" 
                  label="Company Position"
                  name="position"
                  value={position}
                  fullWidth
                  variant="outlined"
                  onChange={(event) => setPosition(event.target.value)}
                />
              </Grid>

              <Grid item
                xs={12}
                md={6}
              >
                <TextField
                  error={errors && errors.city !== undefined ? true : false}
                  helperText={errors && errors.city !== undefined && errors.city}
                  id="city" 
                  label="City"
                  name="city"
                  value={city}
                  fullWidth
                  variant="outlined"
                  required={true}
                  onChange={(event) => setCity(event.target.value)}
                />
              </Grid>

              <Grid item
                xs={12}
                md={6}
              >
                <TextField
                  error={errors && errors.state !== undefined ? true : false}
                  helperText={errors && errors.state !== undefined && errors.state}
                  id="state"
                  label="State"
                  name="state"
                  value={state}
                  fullWidth
                  variant="outlined"
                  required={true}
                  onChange={(event) => setState(event.target.value)}
                />
              </Grid>

              <Grid item
                xs={12}
                md={6}
              >
                <TextField
                  error={errors && errors.zipcode !== undefined ? true : false}
                  helperText={errors && errors.zipcode !== undefined && errors.zipcode}
                  id="zipcode"
                  label="Zipcode"
                  name="zipcode"
                  value={zipcode}
                  fullWidth
                  variant="outlined"
                  required={true}
                  onChange={(event) => setZipcode(event.target.value)}
                />
              </Grid>

              <Grid item
                xs={12}
                md={6}
              >
                <TextField
                  id="phone"
                  label="Phone #"
                  name="phone"
                  value={phone}
                  fullWidth
                  variant="outlined"
                  onChange={handlePhoneChange}
                />
              </Grid>

              <Grid item
                xs={12}
                md={6}
                style={{
                  align: 'center',
                  alignItems: 'center',
                  alignContent: 'center',
                  justify: 'space-between',
                  justifyContent: 'space-between'
                }}
              >
                <FormControlLabel
                  control={(
                    <Checkbox checked={contactPhone} onChange={handleCheckChange} name="contactPhone" style={{color: '#430089'}}/>
                  )}
                  label='Contact Me Via Phone'
                />

                <div className={classes.wrapper}>
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    style={{
                      backgroundColor: "black",
                      color: "white",
                      marginLeft: '25%'
                    }}
                    onClick={handleSubmit}
                  >Apply</Button>
                  {isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string,
  isSubmitting: PropTypes.func.isRequired,
};

export default Hero;
