import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles,
  TextField,
  Grid,
  Fab,
  CircularProgress
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 90,
    paddingBottom: 0
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  },
  image: {
    perspectiveOrigin: 'left center',
    //transformStyle: 'preserve-3d',
    //perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      //transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[0]
    }
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

const CTA = ({ className, clickInvite, thisEmail, hasSubmitted, doOver, ...rest }) => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (event, value) => {
    setEmail(event.target.value);
  }

  const { enqueueSnackbar } = useSnackbar();

  const handleDoOver = () => {
    if (doOver) {
      doOver();
    }
  }

  const handleSubmit = () => {
    setLoading(true);
    let newEmail = email;
    console.log(newEmail);
    const payload = {
      email: newEmail,
      page: 'pageText'
    }
    console.log(payload);

    axios.defaults.baseURL = 'https://us-central1-see-hi.cloudfunctions.net/api';
    axios.post(`/TraksOS_Invite`, payload).then((res) => {
      console.log('response from sedgrid is ', res.data);
      enqueueSnackbar(`${email} Added to List`, {
        variant: 'success',
      });

      if(clickInvite) {
        setLoading(false);
        clickInvite(email);
      }

    })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      })
  }

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">

      
        <Box
          mt={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            marginBottom: 110
          }}
        >
          <Grid container
            spacing={3}
            alignItems="center"
            align="center"
            justify="center"
          >
            <Grid item sm/>
            <Grid item sm>
            <Typography
          variant="h1"
          align="center"
          color="textPrimary"
                style={{
                  fontWeight: 'lighter',
                  marginBottom: 15,
                  fontSize: 35
                }}
              >
                Ready to Start Improving?
        </Typography>
              <Typography
                variant="h1"
                align="center"
                color="secondary"
                style={{
                  marginBottom: 35
                }
                }
              >
                Get Started Today!
        </Typography>
              {!hasSubmitted ? (
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  onChange={handleChange}
                  value={email}
                  variant="outlined"
                />
              ) : (
                  <Fragment>
                    <Typography
                      fullWidth
                      variant="h3"
                      style={{ marginBottom: 25, color: 'green' }}
                    >
                      Email Sent to {thisEmail}
                    </Typography>
                    <Typography
                      fullWidth
                      color="textPrimary"
                      variant="h3"
                    >Please Check Your Junk Folder if you do not see communication</Typography>
                  </Fragment>
                )}

              {!hasSubmitted ? (
                <div className={classes.wrapper}>
                  <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={loading}
                  style={{ marginTop: 15 }}
                >Add To Invite List</Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              ) : (
                  <Button
                    onClick={handleDoOver}
                    variant="contained"
                    color="secondary"
                    size="large"
                    style={{ marginTop: 15 }}
                  >
                    Add Different Email!
                  </Button>
                )}
            </Grid>
            <Grid item sm>
            </Grid>
          </Grid>
        </Box>
        <Typography
          variant="h2"
          align="center"
          color="textPrimary"
          className={classes.title}
        >
          &quot;My team has been using the tool for 2 months!
          <br />
          It is Just What We Needed!!&quot;
        </Typography>
        <Box
          mt={6}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box ml={2}>
            <Typography
              variant="body1"
              color="textPrimary"
            >
              Alex Black
              <Typography
                color="textSecondary"
                display="inline"
                component="span"
              >
                , Business Founder
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Grid container display="flex" alignItems="center" style={{justifyContent: 'center'}}>
          <Grid item sm/>
          <Grid item sm alignContent="center" alignItems="center" justifyContent="center" width="100%">
            <a href="https://join.traksos.com/GetApp">
            <img
                  alt="Presentation"
                  src="/static/home/TraksOS_Phone.png"
                  style={{
                    width: '100%',
                    alignContent: 'center'
                  }}
                />
            </a>
          </Grid>
          <Grid item sm/>
        </Grid>
        
      </Container>
    </div>
  );
};

CTA.propTypes = {
  className: PropTypes.string
};

export default CTA;
