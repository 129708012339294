import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import firebase, { db, storage } from 'src/lib/firebase';

const initialAuthState = {
  isAuthenticated: false,
  isOnboarded: false,
  isInitialised: false,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, isOnboarded, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isOnboarded,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithPhone: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  resendVerification: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signInWithPhone = (phoneNum, verifier) => {
    
    return firebase.auth().signInWithPhoneNumber(phoneNum, verifier)
  }

  const signInWithEmailAndPassword = (email, password) => {
    firebase.auth().signOut();
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password).then((cred => {

      const user = firebase.auth().currentUser;
      return user.sendEmailVerification();
    }))
  };

  const resetPassword = async(email) => {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  const resendVerification = async() => {
    const user = firebase.auth().currentUser;
    return user.sendEmailVerification();
  }

  const changePassword = async (password, newPassword) => {
    return firebase.auth().changePassword(password, newPassword);
  }

  const logout = () => {
    return firebase.auth().signOut();
  };

  const updateUser = async(uuid) => {
    const user = await db.doc(`/PUser/${uuid}`).get();
    if (user.exists) {
      const doc = user.data();
      dispatch({
        type: `AUTH_STATE_CHANGED`,
        payload: {
          isAuthenticated: true,
          isOnboarded: doc.onBoarded,
          user: {
            id: doc.uuid,
            avatar: doc.userProfileImageLink,
            email: doc.email,
            name: doc.username || doc.email,
            firstName: doc.userFirstName || '',
            lastName: doc.userLastName || '',
            streetAddress: doc.streetAddress,
            city: doc.city,
            state: doc.state,
            zipcode: doc.zipcode,
            phone: doc.phone,
            userPaid: doc.userPaid,
            onBoarded: doc.onBoarded,
            company: doc.company,
            companyUuid: doc.companyUuid,
            permissionLevel: doc.permissionLevel,
            tier: doc.plan !== undefined ? doc.plan : 'Free',
            owner: doc.companyUuid === '' ? doc.uuid : doc.companyUuid,
            userNetwork: doc.userNetwork ? doc.userNetwork : [],
            jobRole: doc.jobRole || ''
          }
        }
      })
    }
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((thisUser) => {
      
      const newUser = thisUser;


      if (newUser !== null && newUser.emailVerified === true) {

        return db.doc(`/PUser/${thisUser.uid}`).get().then((user) => {

          if (user.exists) {
            const doc = user.data();

            dispatch({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                isAuthenticated: true,
                isOnboarded: doc.onBoarded,
                user: {
                  id: doc.uuid,
                  avatar: doc.userProfileImageLink,
                  email: doc.email,
                  name: doc.username || doc.email,
                  firstName: doc.userFirstName || '',
                  lastName: doc.userLastName || '',
                  streetAddress: doc.streetAddress,
                  city: doc.city,
                  state: doc.state,
                  zipcode: doc.zipcode,
                  phone: doc.phone,
                  userPaid: doc.userPaid,
                  onBoarded: doc.onBoarded,
                  company: doc.company,
                  companyUuid: doc.companyUuid,
                  permissionLevel: doc.permissionLevel,
                  tier: doc.plan !== undefined ? doc.plan : 'Free',
                  owner: doc.companyUuid === '' ? doc.uuid : doc.companyUuid,
                  userNetwork: doc.userNetwork ? doc.userNetwork : [],
                  jobRole: doc.jobRole || ''
                }
              }
            })
          } else {

            const uuid = newUser.uid;
            
            let addUser = {
              city: '',
              company: '',
              companyUuid: '',
              email: newUser.email,
              jobRole: '',
              onBoarded: false,
              permissionLevel: [],
              phone: '',
              state: '',
              streetAddress: '',
              token: [],
              userApproveCompanyAdd: false,
              userCompanyAddRequested: false,
              userFirstName: '',
              userLastName: '',
              userNetwork: [],
              userPaid: false,
              userProfileImageLink: '',
              userSubscriptionEnd: '',
              userSubscriptionStart: '',
              username: '',
              uuid: uuid,
              zipcode: '',
              platform: 'Web'
            }

            db.doc(`/PUser/${addUser.uuid}`).set(addUser);

            dispatch({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                isAuthenticated: true,
                isOnboarded: false,
                user: {
                  id: user.uid,
                  avatar: '',
                  email: user.email,
                  name: '',
                  city: '',
                  state: '',
                  zipcode: '',
                  phone: '',
                  userPaid: false,
                  onBoarded: false,
                  company: '',
                  companyUuid: '',
                  permissionLevel: [],
                  userNetwork: [],
                  tier: 'Free',
                  owner: uuid
                }
              }
            })
          }
        })
      } else {

        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            isOnboarded: false,
            user: null
          }
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithPhone,
        signInWithGoogle,
        changePassword,
        updateUser,
        resendVerification,
        resetPassword,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
